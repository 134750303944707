@import "./../../utils/constants";

.project-border {
  position: relative;
  border-radius: 2rem;
  margin: 1rem;
  box-shadow: 0 1px 2px $shadowBefore;
  background-color: $bgColor02;
}

.project-section {

}

.project-section > * {
  flex: 1;
  margin-bottom: 30px;
}

.project-section > h1 {
  text-align: center;
  font-size: 25px;
  margin: 1rem;
  font-weight: normal;
  padding: 20px 20px;
  color: $titleColor !important;
}

.project-list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: $desktop-width) {

}

@media (max-width: $tablet-width) {

}