@import "./utils/constants";

.App {
  font-family: "Roboto Regular", monospace;
  margin-bottom: 40px;
}

.App-Sections {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  transition-duration: 0.1s;
}

@media (max-width: $tablet-width)
{
  .App-Sections {
    width: 80%;
  }
}

@media (max-width: $phone-width)
{
  .App-Sections {
    width: 90%;
  }
}
