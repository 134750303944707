@import "../../utils/constants";

.greet-border {
    position: relative;
    border-radius: 2rem;
    margin: 1rem;
    box-shadow: 0 1px 2px $shadowBefore;
    background-color: $bgColor02;
}

.greet-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
}

// ------------------- About Me ----------------------

.greet-text {
    margin: 1rem;
}

.greet-text > * {
    padding: 20px 20px;
    line-height: 1.1;
    font-weight: normal;
}

.greet-text > h1 {
    text-align: left;
    font-size: 25px;
    color: $titleColor !important;
}

.greet-text > h2 {
    text-align: left;
    font-size: 20px;
    margin-top: -25px;
    color: $subTitleColor !important;
}

.greet-text > p {
    text-align: left;
    font-size: 16px;
    margin-top: -30px;
    margin-left: 20px;
    line-height: 1.5;
}

// ----------------------------------------------------

.greet-headshot {
    align-content: center;
    padding: 20px 20px;
    margin-right: 20px;
}

.greet-headshot > img {
    object-fit: cover;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;

    box-shadow: 0 1px 2px $shadowAfter;
}

@media (max-width: $desktop-width)
{

}

@media (max-width: $tablet-width)
{
    .greet-section {
        display: inline-block;
    }

    .greet-headshot {
        display: none;
    }

    .greet-text > h1 {
        text-align: center;
    }

    .greet-text > h2 {
        text-align: center;
    }

    .greet-text > p {
        text-align: center;
        margin-left: 0;
    }
}

@media (max-width: $phone-width)
{

}