@import "../../utils/constants";

.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 1rem;
  margin: 1rem;

  width: 45%;
  box-shadow: 0 1px 2px $shadowBefore;
  background-color: $bgColor01;
}

.project-card-text > * {
  font-weight: normal;
  margin: 1.5rem 1rem 1rem 1.5rem;
}

.project-card-text > h1 {
  font-size: 25px;
}

.project-card-text > h2 {
  font-size: 16px;
  margin: 0 0 0.5rem 2rem;
  color: $subTitleColor;
  line-height: 1;
}

.project-card-details > * {
  padding: 10px 10px;
  font-weight: normal;
}

.project-card-details > p {
  font-size: 16px;
  margin: -1rem 0 0.5rem 1.5rem;
}

.project-card-buttons {
  margin: 1rem;
}

@media (max-width: $desktop-width)
{
  .project-card {
    width: 70%
  }
}

@media (max-width: $tablet-width)
{
  .project-card {
    width: 80%;
  }

  .project-card-text > h2 {
    margin: 0 0.5rem 0.5rem 1.5rem;
  }

  .project-card-details > * {
    padding: 0 0;
  }

  .project-card-details > p {
    margin-left: 0;
  }

}