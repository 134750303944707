@import "../../utils/constants";

.flip-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  width: 45%;
  transition: all 0.3s ease-in-out;

}

.flip-container::after {
  position: absolute;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px $shadowAfter;
  transition: opacity 0.3s ease-in-out;
}

.flip-container:hover {
  transform: scale(1.1, 1.1);
}

.flip-container:hover::after {
  opacity: 1;
}

.flip-card {
  border-radius: 2rem;
  margin: 1.5rem;
  box-shadow: 0 1px 2px $shadowBefore;
  background-color: $bgColor01;

  transform-style: preserve-3d;
  transition: 0.5s linear 0.1s;

  &.showBack {
    transform: rotateY(180deg);
  }
}

.card-front {
  backface-visibility: hidden;
  transform: rotateY(0deg);

  position: absolute;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.card-body {

}

.front-body > * {
  line-height: 1.1;
  font-weight: normal;
  margin: 1.5rem;
  align-content: center;
}

.card-front > * {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.front-body > img {
  object-fit: cover;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;

  box-shadow: 0 1px 2px $shadowAfter;
}

.front-body > h1 {
  font-size: 25px;
}

.back-body > * {
  line-height: 1.1;
  font-weight: normal;
  margin: 1.5rem;
}

.back-body > h2 {
  font-size: 16px;
  color: $subTitleColor;
}

.back-body > p {
  font-size: 16px;
}

@media (max-width: $desktop-width)
{
  .flip-container {
    width: 70%;
  }

  .card-front > * {
    flex-direction: column;

  }
}

@media (max-width: $phone-width)
{
  .flip-container {
    width: 80%
  }
}