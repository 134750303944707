// general
$textColor: #000000;
$bgColor01: #FAFAFF;
$bgColor02: #F5F1ED;

// greeting
$titleColor: #000000;
$subTitleColor: #605F5E;

// header
$headerColor: #E3B23C;
$headerText: #000000;
$headerHover: #FFC744;

// shadow-box transition colors
$shadowBefore: rgba(0, 0, 0, 0.5);
$shadowAfter: rgba(0, 0, 0, 1);

// media query sizes
$desktop-width: 1024px;
$tablet-width: 768px;
$phone-width: 640px;
